<template>
	<el-dialog :title="!dataForm.recId ? '新增项目人员' : '修改项目人员'" :close-on-click-modal="false" :visible.sync="visible"
			   width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-form-item label="姓名" prop="nodeName">
				<el-input class="selItemInput" v-model="dataForm.personName" placeholder="姓名" style="width:300px"></el-input>
			</el-form-item>
			<el-form-item label="职务">
				<el-select class="selItemInput" disabled v-model="dataForm.jobKind" placeholder="请选择">
					<el-option
					  v-for="item in personJobKindList"
					  :key="item.displayValue"
					  :label="item.displayName"
					  :value="item.displayValue">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="专业">
				<el-select class="selItemInput" v-model="dataForm.personMajor" placeholder="请选择">
					<el-option
					  v-for="item in personMajorList"
					  :key="item.displayValue"
					  :label="item.displayName"
					  :value="item.displayValue">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "personnelCommun-add-or-update",
		data() {
			return {
				nodeinfoList : [],
				visible: false,
				personMajorList:[],
				personJobKindList:[],
				dataForm: {
					workNo: $common.getItem("workNo"),
					recId:"",
					personName:"",
					personMajor:"",
					jobKind:"2"
				},
				dataRule: {
				},
			}
		},
		methods: {
			init(id) {
				this.getPersonJobKind();
				this.getPersonMajor();
				this.dataForm = {
					workNo: $common.getItem("workNo"),
					recId:"",
					personName:"",
					personMajor:"",
					jobKind:"2"
				},
				this.dataForm.recId = id?id:0;
				if(id){
					this.getDetail();
				}
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			getDetail(){
				this.$http({
				  url: this.$store.state.httpUrl + "/business/projectpersoninfo/info/"+this.dataForm.recId,
				  method: "get",
				  params: {},
				}).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					this.dataForm = data.body
				  }
				});
			},
			getPersonJobKind(){
				this.$http({
				  url:  this.$store.state.httpUrl + "/common/getPersonJobKind",
				  method: "get",
				  params: {},
				}).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					this.personJobKindList = data.body;
				  }
				});
			},
			getPersonMajor(){
				this.$http({
				  url:  this.$store.state.httpUrl + "/common/getPersonMajor/",
				  method: "get",
				  params: {},
				}).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					this.personMajorList = data.body;
				  }
				});
			},
			dataFormSubmit() {
			  this.$refs["dataForm"].validate(valid => {
			    if (valid) {
				  let _url = "/business/projectpersoninfo/save";
				  if(this.dataForm.recId && this.dataForm.recId != 0){
					_url = "/business/projectpersoninfo/update";
				  }
			      this.$http({
			        url: this.$store.state.httpUrl + _url,
			        method: "post",
			        data: this.$http.adornData(this.dataForm)
			      }).then(({ data }) => {
			        if (data && data.resultCode === 200) {
			          this.$message({
			            message: "操作成功",
			            type: "success",
			            duration: 1500,
			            onClose: () => {
			              this.visible = false;
			              this.$emit("refreshDataList");
			            }
			          });
			        } else {
			          this.$message.error(data.msg);
			        }
			      });
			    }
			  });
			}
		}
	}
</script>
<style scoped>

</style>
